/* eslint-disable jsx-a11y/anchor-is-valid */
const BcxSectionF = () => {
    return (
        <section id="sectionF">
            <div className="title">
                <h2>
                    <strong>Documentação</strong>
                    <br />
                    necessária
                </h2>
            </div>
            <div className="containerF">
                <div className="texto">
                    <p>
                        • RG, CPF e comprovante de endereço e renda dos sócios. Pessoas
                        casadas ou com união estável devem apresentar também documentação do
                        cônjuge. Cópias devem ser coloridas.
                    </p>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p>• Inscrição municipal</p>
                        <a style={{ alignSelf: 'baseline' }}
                            href={
                                'https://camaqua.atende.net/autoatendimento/servicos/certidao-negativa-de-debitos/'
                            }
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            Clique aqui
                        </a>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p>• Inscrição do MEI</p>
                        <a style={{ alignSelf: 'baseline' }}
                            href={
                                'https://camaqua.atende.net/autoatendimento/servicos/emissao-de-processo-digital/'
                            }
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            Clique aqui
                        </a>
                    </div>
                    <br />

                    <p>
                        <strong>Mais documentos abaixo, conforme tipo de empresa:</strong>
                    </p>
                    <p className="tipoEmpresa">
                        • Se empresa enquadrada como MEI:
                        <ul>
                            <li>
                                - CCMEI: Certificado de Condição de Microempreendedor
                                Individual.
                            </li>
                            <li>- DASN SIMEI: Declaração Anual do SIMEI.</li>
                        </ul>
                    </p>
                    <p className="tipoEmpresa">
                        • Se empresa enquadrada como ME / EPP:
                        <ul>
                            <li>- Declaração de Firma Individual ou Contrato Social</li>
                            <li>
                                - <strong>Simples Nacional:</strong> <br />
                                PGDAS-D. Programa Gerador do Documento de Arrecadação do Simples
                                Nacional Declaratório 2022, com faturamento dos últimos 12
                                meses.
                            </li>
                            <li>
                                - <strong>Lucro Presumido:</strong> <br />
                                SPED Contábil | Recibo, Balanço e DRE
                            </li>
                        </ul>
                    </p>
                    <div className="asterisco">
                        <p>
                            * A documentação completa será solicitada somente após a análise
                            das informações fornecidas no formulário. Preencha com seus dados
                            e aguarde contato da sua cooperativa de crédito ou da RS Garanti.
                        </p>
                        <p>
                            * Se você não é associado (a) de uma cooperativa de crédito
                            participante será necessário a abertura de uma conta corrente.
                        </p>
                    </div>
                </div>
                <div className="imagem">
                    <span />
                </div>
            </div>
        </section>
    )
}

export default BcxSectionF